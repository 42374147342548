.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0rem 1rem;
    margin-top: 1.5rem auto;
  }
  
  .left-side {
    margin-right: 10px; /* Adjust as needed */
    font-size: 3rem;
    font-weight: 500;
  }
  
  .right-side select {
    width: 150px; /* Adjust the width as needed */
    padding: 5px;
  }
  